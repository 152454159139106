<template>
    <div class=head>
        <div class=wrapper>
                    <router-link class=title :to="'/'" >Shree K. Nayar</router-link>
                    <Navigation class=nav /> 
        </div>
    </div>    
</template>
<script>
import Navigation from "./Navigation.vue"
export default {
    name:'Header',
    components: {
        Navigation
    }

}
</script>
<style scoped>
    .head{
        background-color: rgb(0, 0, 0);
        height:100px;
        min-width:1650px;
  
    }
    .wrapper{
        width:1400px;
        margin:auto;
              display:flex;
    }
    .title{
        padding-top:30px;
        padding-left:150px;
        text-decoration: none;
        font-size:43px;
        font-weight: 600;
        font-family:'Raleway', sans-serif;;
        color: rgb(255, 255, 255);
    }
    .nav{
        padding-top:70px;
        padding-left:200px;
  
    }

</style>